import { useState } from 'react';

const Menu = () => {

  const [isOpen, setIsOpen] = useState(false);

  const pdfUrl = `${process.env.PUBLIC_URL}/regulamin-hotelslowik.pdf`;

  const handleMenuClick = () => {
    if (isOpen === false) {
      setIsOpen(true)
      document.getElementById("menu").style.width = '80vw';
    }

    if (isOpen === true) {
      setIsOpen(false)
      document.getElementById("menu").style.width = '0vw';
    }
  }

    return (
      <div className="menu-page-wrapper">
      
      <div className="navbar">

        <div className='navbar-wrapper'>
          <div style={{cursor: 'pointer'}}>
            <a href='/' className="navbar-logo">
            <img src={require('../images/logo.png')} className='navbar-logo-photo' alt='logo'></img>
            <div className='navbar-logo-text'>
              <p style={{fontSize: 2.5 + "em", fontWeight: "bold"}}>HOTEL</p>
              <p style={{fontSize: 2 + "em", fontStyle: "italic"}}>Słowik</p>
            </div>
            </a>
          </div>
          <div className="navbar-menu">
            <a href='/?scroll=home' className='nav-button'>HOME</a>
            <a href='/?scroll=about' className='nav-button'>O NAS</a>
            <a href='/oferta' className='nav-button'>OFERTA</a>
            <a href="/menu" className='nav-button'>MENU</a>
            <a href="/galeria" className='nav-button'>GALERIA</a>
            <a href='/?scroll=contact' className='nav-button'>KONTAKT</a>
          </div>
        </div>
        <div className='navbar-mobile'>
          <a href='/' className='navbar-logo-mobile'>
            <img src={require('../images/logo.png')} className='navbar-logo-photo' alt='logo'></img>
            <p style={{fontSize: 2 + 'em', color: "white"}}>Hotel Słowik</p>
          </a>
        <img src={require('../images/menu.png')} style={{cursor: 'pointer'}} alt='btn' className='nav-menu-btn' onClick={handleMenuClick}></img>
        <div className='navbar-list' id="menu">
          <div className="menu-list">
            <img src={require('../images/menu.png')} style={{cursor: 'pointer'}} className='nav-menu-btn-list' alt='btn' onClick={handleMenuClick}></img>
            <a href='/?scroll=home' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Home</a>
            <a href='/?scroll=about' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>O nas</a>
            <a href='/oferta' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Oferta</a>
            <a href='/menu' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Menu</a>
            <a href='/galeria' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Galeria</a>
          </div>
          <a href='/?scroll=contact' className='contact-btn-list' style={{textAlign: 'center', alignContent: 'center'}}>KONTAKT</a>
        </div>
        </div>

      </div>

       <div className='menu-page'>
          <div className='menu-page-text'>
             Menu
             <div className='menu-page-text-line'></div>
          </div>
         <div className='menu-page-desc'>
            Oto aktualne menu w naszej restauracji oraz menu dla klientów indywidualnych dla przyjęć okolicznościowych.
         </div>
             
       </div>

       <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.9684201570853!2d20.55623207685325!3d50.85026905849533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471787e9b60a3c55%3A0x6dae4072ec3bf63b!2sKrakowska%20307%2C%2025-801%20Kielce!5e0!3m2!1spl!2spl!4v1713007093560!5m2!1spl!2spl" height="550" style={{border: 0, width: 100 + "%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

       <div className='footer'>
        <div className='footer-text'>
          Hotel Słowik
        </div>

        <div className='footer-info'>
          <div className='footer-location'>
            <img src={require('../images/location.png')} style={{height: 8 + "vh", marginLeft: 1.5 + "vw"}} className='footer-location-photo footer-icon' alt='img'></img>
            <div className='footer-text-div'>
              <p className='text-footer'>Krakowska 307</p>
              <p className='text-footer'>Kielce 25-801</p>
            </div>
          </div>
          <div className='footer-phone'>
            <img src={require('../images/phone.png')} style={{marginLeft: 2 + "vw"}} className='footer-phone-photo footer-icon' alt='img'></img>
            <div className='footer-text-div'>
              <p className='text-footer'>+48 607 939 118</p>
              <p className='text-footer'>+48 607 093 667</p>
            </div>
          </div>
        </div>

        <div className='footer-additional'>
          <div className='footer-mail'>
            <img src={require('../images/mail.png')} style={{marginLeft: 2 + "vw"}} className='footer-mail-photo footer-icon' alt='img'></img>
            <p style={{width: 8 + "vw", marginLeft: 1 + "vw"}}>hotelslowik@wp.pl</p>
          </div>
          {/* <div className='footer-rules'>
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className='footer-rules-text'>Regulamin oraz polityka prywatności</a>
          </div> */}
          <div className='footer-socials'>
            <a href='https://www.instagram.com/'><img src={require('../images/instagram.png')} className='footer-instagram-photo socials-icon' alt='img'></img></a>
            <div className='footer-line'></div>
            <a href='https://www.facebook.com/'><img src={require('../images/facebook.png')} className='contact-facebook-photo socials-icon' alt='img'></img></a>
          </div>
        </div>
      </div>

     </div>
    );
   };
   export default Menu;