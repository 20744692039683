import { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

function App() {

  const [isChecked, setIsChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const pdfUrlRules = `${process.env.PUBLIC_URL}/regulamin-hotelowy.pdf`;
  const pdfUrlPrivacy = `${process.env.PUBLIC_URL}/polityka-prywatnosci.pdf`;

  const handleHomeClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('main');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - (navbarHeight - 15),
        behavior: 'smooth'
      })
    }
  }

  const handleAboutClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('offer');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - (navbarHeight - 15),
        behavior: 'smooth'
      })
    }
  }

  const handleContactClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('contact');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - (navbarHeight - 15),
        behavior: 'smooth'
      })
    }
  }

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  reveal();

  function revealRight() {
    var reveals = document.querySelectorAll(".reveal-right");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", revealRight);
  
  revealRight();

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if(isChecked) {
      try {
      emailjs
      .sendForm('service_b4oi3ij', 'template_n39raci', form.current, {
        publicKey: 'vaEEki0cVOHS6jfDZ',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      } catch (error) {
          console.error('Error sending email:', error);
          alert('Error sending email.');
        }
    } else {
      alert('Proszę udzielić zgodę na przetwarzanie danych osobowych aby wysłać zapytanie.');
    }
  };

  const handleMenuClick = () => {
    if (isOpen === false) {
      setIsOpen(true)
      document.getElementById("menu").style.width = '80vw';
    }

    if (isOpen === true) {
      setIsOpen(false)
      document.getElementById("menu").style.width = '0vw';
    }
  }


  useEffect(() => {
    if (searchParams.has('scroll')) {
      if (searchParams.get('scroll') === 'home') {
        handleHomeClick();
      }
      if (searchParams.get('scroll') ==='about') {
        handleAboutClick();
      }
      if (searchParams.get('scroll') === 'contact') {
        handleContactClick();
      }
    }
  }, [])

  return (
    <div className="App">

      <div className="navbar">

        <div className='navbar-wrapper'>
          <div style={{cursor: 'pointer'}}>
            <a href='/' className="navbar-logo">
            <img src={require('./images/logo.png')} className='navbar-logo-photo' alt='logo'></img>
            <div className='navbar-logo-text'>
              <p style={{fontSize: 2.5 + "em", fontWeight: "bold"}}>HOTEL</p>
              <p style={{fontSize: 2 + "em", fontStyle: "italic"}}>Słowik</p>
            </div>
            </a>
          </div>
          <div className="navbar-menu">
            <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleHomeClick}>HOME</span>
            <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleAboutClick}>O NAS</span>
            <a href='/oferta' className='nav-button'>OFERTA</a>
            <a href="/menu" className='nav-button'>MENU</a>
            <a href="/galeria" className='nav-button'>GALERIA</a>
            <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleContactClick}>KONTAKT</span>
          </div>
        </div>
        <div className='navbar-mobile'>
          <a href='/' className='navbar-logo-mobile'>
            <img src={require('./images/logo.png')} className='navbar-logo-photo' alt='logo'></img>
            <p style={{fontSize: 2 + 'em', color: "white"}}>Hotel Słowik</p>
          </a>
        <img src={require('./images/menu.png')} style={{cursor: 'pointer'}} alt='btn' className='nav-menu-btn' onClick={handleMenuClick}></img>
        <div className='navbar-list' id="menu">
          <div className="menu-list">
            <img src={require('./images/menu.png')} style={{cursor: 'pointer'}} className='nav-menu-btn-list' alt='btn' onClick={handleMenuClick}></img>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleHomeClick}>Home</span>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleAboutClick}>O nas</span>
            <a href='/oferta' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Oferta</a>
            <a href='/menu' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Menu</a>
            <a href='/galeria' style={{cursor: 'pointer', color: 'white'}} className='nav-button-list'>Galeria</a>
          </div>
          <button className='contact-btn-list' onClick={handleContactClick}>KONTAKT</button>
        </div>
        </div>

      </div>

      <div className='main'>
        <div className='main-text'>
          <p style={{fontWeight: "bold", fontSize: 7 + "em", width: 20 + "vw"}}>HOTEL</p>
          <p style={{fontStyle: "italic", fontSize: 5 + "em", width: 20 + "vw"}}>Słowik</p>
        </div>
        <div className='main-text-mobile'>
          <p style={{fontWeight: "bold"}}>HOTEL</p>
          <p style={{fontStyle: "italic"}}>Słowik</p>
        </div>
        <div className='main-line'>

        </div>
        <div className='main-desc'>
          W Hotelu Słowik tworzymy niezapomniane chwile, zapewniając kompleksową obsługę imprez okolicznościowych oraz firmowych, doskonałe posiłki w naszej restauracji oraz komfortowy nocleg w naszych pokojach hotelowych.
        </div>
        <div className='main-more'>
          <img src={require('./images/arrow.png')} className='main-arrow-photo' alt='arrow'></img>
          <div className='main-more-wrapper'>
            <p>Dowiedz</p>
            <p>się więcej</p>
          </div>
        </div>
      </div>

      <div className='offer'>
        <div className='offer-text'>
          Co oferujemy?
        </div>

        <div className='offer-tile reveal'>
          <img src={require('./images/offer1.jpg')} className='offer-tile-photo' alt='img'></img>
          <div className='offer-tile-content'>
            <p className='offer-tile-title'>Imprezy okolicznościowe oraz firmowe</p>
            <div className='offer-tile-desc'>
              <div className='offer-tile-line'>

              </div>
              <p>Niech nasz hotel stanie się miejscem, gdzie spełniamy marzenia o idealnym przyjęciu. Organizujemy różnorodne uroczystości, takie jak jubileusze, urodziny, komunie czy chrzciny. Zapewniamy kompleksową obsługę, dbając o każdy detal, aby impreza była wyjątkowa i niezapomniana.</p>
            </div>
          </div>
        </div>

        <div className='offer-tile reveal-right offer-right'>
          <div className='offer-tile-content'>
            <p className='offer-tile-title'>Restauracja</p>
            <div className='offer-tile-desc'>
              <div className='offer-tile-line'>

              </div>
              <p>Nasza restauracja to miejsce, gdzie spotykają się smaki tradycji i nowoczesności. Nasz utalentowany zespół kucharzy serwuje pyszne dania kuchni polskiej i międzynarodowej, korzystając ze świeżych składników. Ciepła atmosfera oraz elegancki wystrój sprawiają, że posiłek w naszej restauracji staje się przyjemnością.</p>
            </div>
          </div>
          <img src={require('./images/offer2.jpg')} className='offer-tile-photo' alt='img'></img>
        </div>

        <div className='offer-tile reveal'>
          <img src={require('./images/offer3.jpg')} className='offer-tile-photo' alt='img'></img>
          <div className='offer-tile-content'>
            <p className='offer-tile-title'>Zakwaterowanie hotelowe</p>
            <div className='offer-tile-desc'>
              <div className='offer-tile-line'>

              </div>
              <p>Zapewniamy komfortowy nocleg w naszych przytulnych pokojach hotelowych. Każdy pokój został urządzony z myślą o maksymalnym komforcie naszych gości. Wyposażone w nowoczesne udogodnienia, zapewniają spokojny i relaksujący wypoczynek po dniu pełnym wrażeń.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='question'>
      <div className='question-text'>
        Wyślij nam zapytanie
      </div>
      <form ref={form} id='contact-form' className='question-tile' onSubmit={sendEmail}>
        <div className='question-tile-info'>
          <div className='question-tile-info-input'>
            Imię i nazwisko
            <input
              type='text'
              className='input-small'
              name='name'
            />
          </div>
          <div className='question-tile-info-input'>
            Adres e-mail
            <input
              type='email'
              className='input-small'
              name='email'
            />
          </div>
          <div className='question-tile-info-input'>
            Numer telefonu
            <input
              type='number'
              className='input-small'
              name='phoneNumber'
            />
          </div>
        </div>
        <div className='question-tile-message'>
          Wiadomość
          <textarea
            className='input-big'
            name='message'
          />
        </div>
        <div className='question-checkbox'>
          <input
            type='checkbox'
            className='checkbox'
            onChange={handleCheckboxChange}
          />
          Wyrażam zgodę na przetwarzanie moich danych osobowych w celu kontaktu oraz odpowiedzi z Hotelem Słowik
        </div>
        <input type='submit' value="Wyślij" className='send-btn'/>
      </form>
    </div>

      <div className='contact'>
        <div className='contact-text'>
          Kontakt
        </div>
        <div className='contact-holder'>
          <div className='contact-content'>
            <img src={require('./images/location.png')} style={{height: 20 + "vh"}} className='contact-location-photo icon' alt='img'></img>
            <p style={{fontSize: 1.5 + "em", color: "#0e3723"}}>Adres</p>
            <div>
              <p>Krakowska 307</p>
              <p>Kielce 25-801</p>
            </div>
          </div>

          <div className='contact-line'></div>

          <div className='contact-content'>
            <img src={require('./images/phone.png')} style={{marginBottom: 2.5 + "vh", marginTop: 2 + "vh"}} className='contact-phone-photo icon' alt='img'></img>
            <p style={{fontSize: 1.5 + "em", color: "#0e3723"}}>Telefon</p>
            <div>
              <p>+48 607 939 118</p>
              <p>+48 607 093 667</p>
            </div>
          </div>

          <div className='contact-line'></div>

          <div className='contact-content' style={{height: 35 + "vh"}}>
            <img src={require('./images/mail.png')} style={{height: 16 + "vh"}} className='contact-mail-photo icon' alt='img'></img>
            <p style={{fontSize: 1.5 + "em", color: "#0e3723"}}>E-mail</p>
            <div>
              <p>hotelslowik@wp.pl</p>
            </div>
          </div>

          <div className='contact-line'></div>

          <div className='contact-content'>
            <img src={require('./images/clock.png')} style={{marginBottom: 3 + "vh", marginTop: 2 + "vh"}} className='contact-clock-photo icon' alt='img'></img>
            <p style={{fontSize: 1.2 + "em", color: "#0e3723"}} className='hours-mobile'>Godziny otwarcia restauracji</p>
            <div>
              <p>Pon-Pt: 10:00-21:00</p>
              <p>Sob-Nd: 14:00-21:00</p>
            </div>
          </div>
        </div>

        <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.9684201570853!2d20.55623207685325!3d50.85026905849533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471787e9b60a3c55%3A0x6dae4072ec3bf63b!2sKrakowska%20307%2C%2025-801%20Kielce!5e0!3m2!1spl!2spl!4v1713007093560!5m2!1spl!2spl" height="550" style={{border: 0, width: 100 + "%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <div className='footer'>
        <div className='footer-text'>
          Hotel Słowik
        </div>

        <div className='footer-info'>
          <div className='footer-location'>
            <img src={require('./images/location.png')} style={{height: 8 + "vh", marginLeft: 1.5 + "vw"}} className='footer-location-photo footer-icon' alt='img'></img>
            <div className='footer-text-div'>
              <p className='text-footer'>Krakowska 307</p>
              <p className='text-footer'>Kielce 25-801</p>
            </div>
          </div>
          <div className='footer-phone'>
            <img src={require('./images/phone.png')} style={{marginLeft: 2 + "vw"}} className='footer-phone-photo footer-icon' alt='img'></img>
            <div className='footer-text-div'>
              <p className='text-footer'>+48 607 939 118</p>
              <p className='text-footer'>+48 607 093 667</p>
            </div>
          </div>
        </div>

        <div className='footer-additional'>
          <div className='footer-mail'>
            <img src={require('./images/mail.png')} style={{marginLeft: 2 + "vw"}} className='footer-mail-photo footer-icon' alt='img'></img>
            <p style={{width: 8 + "vw", marginLeft: 1 + "vw"}}>hotelslowik@wp.pl</p>
          </div>
          <div className='footer-rules'>
            <a href={pdfUrlRules} target="_blank" rel="noopener noreferrer" className='footer-rules-text'>Regulamin</a>
            <a href={pdfUrlPrivacy} target="_blank" rel="noopener noreferrer" className='footer-rules-text'>Polityka Prywatności</a>
          </div>
          <div className='footer-socials'>
            <a href='https://www.instagram.com/'><img src={require('./images/instagram.png')} className='footer-instagram-photo socials-icon' alt='img'></img></a>
            <div className='footer-line'></div>
            <a href='https://www.facebook.com/'><img src={require('./images/facebook.png')} className='contact-facebook-photo socials-icon' alt='img'></img></a>
          </div>
        </div>
      </div>

      

    </div>
  );
}

export default App;
